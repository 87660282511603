/*******************************
         Site Overrides
*******************************/

/* Frontsite */
@{fs-parent-selector} {
  @{ui-container} {
    &.fs-headline-section {
      min-height: 65vh;
    }
  }
}
