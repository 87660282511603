/*******************************
   Site Overrides - CDS ILS
*******************************/

h2.logo,h3.logo {
   margin-bottom: 0;
}

a.logo {
   color: white;
}

a.logo.media-font-size{
   font-size: inherit;
}

div.sublogo {
   font-size: small;
}

#document-request{
   .custom-label {
      display: inline-block;
      margin: 0em 0em 0.28571429rem 0em;
      color: rgba(0, 0, 0, 0.87);
      font-size: 0.92857143em;
      font-weight: bold;
      text-transform: none;
   }

   .label-subtitle {
      display: block;
      font-size: 0.92857143em;
   }
}

.display-flex {
   display: flex;
}

@media only screen and (max-width: 440px) {
  .right.menu {
     font-size: 0.8em;
  }
}

@media only screen and (max-width: 400px) {
  a.logo.media-font-size {
    font-size: small;
  }
  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 340px) {
  a.logo.media-font-size {
     font-size: x-small;
  }
}
