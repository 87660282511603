/*******************************
         Site Overrides
*******************************/

.sticky-table-header{
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 999;
}
